<template>
  <div class="job-apply login-page">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">

          <div v-show="reset==1" class="alert alert-success alert-dismissible fade show" role="alert">
            We have <b>reset your password</b>. Please check your email to access your password.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>


          <form @submit.prevent="resetPassword" class="job-apply-section shadow">
            <div class="row g-3">
              <div class="login-header mb-4 pb-3 text-center">
                <div class="login-logo mb-4">
                  <img src="/assets/forgotLogo.svg" alt="">
                </div>
                <h4 class="login-title"><b>Reset Your Password</b></h4>
                <p>We've send you an email with OTP code, please fill the form bellow and hit enter to reset your
                  password</p>
              </div>
              <div class="col-md-12">
                <label class="" for="email">Email Address</label>
                <input type="text" id="email" v-validate="'required|email'" v-model="reset_password.email"
                       class="form-control"
                       placeholder="email@mail.com">
                <p v-if="from_errors.email" class="text-danger">{{ from_errors.email[0] }}</p>
              </div>
              <div class="col-md-12">
                <label class="" for="otp">OTP</label>
                <input type="text" id="otp" v-validate="'required'" v-model="reset_password.otp"
                       class="form-control"
                       :class="from_errors.otp ? 'is-invalid' : ''"
                       placeholder="OTP">
                <p v-if="from_errors.otp" class="text-danger">{{ from_errors.otp[0] }}</p>
              </div>

              <div class="col-md-12">
                <label class="" for="password">New Password</label>
                <input type="password" id="password" v-validate="'required|min:6'" v-model="reset_password.password"
                       class="form-control" ref="password"
                       placeholder="**********">
                <p v-if="from_errors.password" class="text-danger">{{ from_errors.password[0] }}</p>
              </div>

              <div class="col-md-12 mb-3">
                <label class="" for="re_pass">Re-Type Password</label>
                <input type="password" id="re_pass" v-validate="'required|confirmed:password'"
                       v-model="reset_password.re_type_password"
                       class="form-control mb-3"
                       placeholder="**********">
                <p v-if="from_errors.re_type_password" class="text-danger">{{ from_errors.re_type_password[0] }}</p>
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="login-btn">
                Reset password
              </button>
              <router-link to="/login" class="text-center forgot-password d-block mt-3">
                <u>Login</u>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "ResetPassword",
  data: () => ({
    reset_password: {
      email: '',
      otp: '',
      password: '',
      re_type_password: ''
    },
    from_errors: {},
    reset: 0,
  }),
  methods: {
    resetPassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/reset-password/', this.reset_password).then(res => {
            // TODO : show a message then redirect
            this.$router.push('/login')
          }).catch(err => {
            this.from_errors = err.response.data
            this.$validator.errors.items = err.response.data
            // this.from_errors.push(err.response.data)
            // this.errors = err.response
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>